import { Route } from "@angular/router";

export const competitorAnalyseRoute: Route = {
    path: "competitor-analyse",
    loadChildren:
        () => import('./competitor-analyse.module').then(m => m.CompetitorAnalyseModule),
    data: {
        menuParent: "intellect",
        menuLabel: "竞品分析",
        requiredPrivilege: "intellect:CompetitorAnalyse:view",
    },
};
