import {Route} from "@angular/router";

export const userRoute: Route = {
    path: "user-manage",
    loadChildren: () => import('./user.module').then(m => m.UserModule),
    data: {
        menuParent: "system",
        menuLabel: "用户管理",
        requiredPrivilege: "system:user:view"
    }
};
