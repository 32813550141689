import {Route} from "@angular/router";

export const salesBySceneRoute: Route = {
    path: "sales-by-scene",
    loadChildren: () => import('./sales-by-scene.module').then(m => m.SalesBySceneModule),
    data: {
        menuParent: "statistics",
        menuLabel: "场景销售",
        requiredPrivilege: "statistics:scenesale:view"
    }
};
