<div class="standard-list-page">
    <ips-standard-title-bar title="欢迎使用DAC"></ips-standard-title-bar>
    <div class="content-area" style="background-color: transparent">
        <nz-card style="width: 300px" [nzTitle]="'Welcome'">
            <nz-card-meta [nzAvatar]="avatarTemplate" [nzTitle]="'欢迎使用DAC'"
                          [nzDescription]="'欢迎使用DAC后台管理系统'"></nz-card-meta>
        </nz-card>
        <ng-template #avatarTemplate>
            <i nz-icon nzType="user"></i>
        </ng-template>
    </div>
</div>
