import { Routes } from "@angular/router";
import { DashboardComponent } from "./public/dashboard/dashboard.component";
import { salesByGoodsRoute } from "./statistics/sales-by-goods/sales-by-goods.route";
import { salesBySceneRoute } from "./statistics/sales-by-scene/sales-by-scene.route";
import { salesByCityRoute } from "./statistics/sales-by-city/sales-by-city.route";
import { goodsSellerRoute } from "./statistics/goods-seller/goods-seller.route";
import { orderListRoute } from "./order/order-list/order-list.route";
import { totalStatisticsRoute } from "./statistics/total-statistics/total-statistics.route";
import { roleRoute } from "./system/role/role.route";
import { platRoute } from "./system/plat/plat.route";
import { userRoute } from "./system/user/user.route";
import { goodsRoute } from "./system/goods/goods.route";
import { competitorAnalyseRoute } from "./intellect/competitor-analyse/competitor-analyse.route";
import { popularizeRoute } from "./intellect/popularize/popularize.route";
import { displayRoute } from "./system/display/display.route";
import { manageUniformInterfaceAuthRoute } from "./manage-interface/manage-uniform-interface-auth/manage-uniform-interface-auth.route";

export const pagesRoute: Routes = [
    { path: "", component: DashboardComponent },
    popularizeRoute,
    competitorAnalyseRoute,
    totalStatisticsRoute,
    salesByGoodsRoute,
    salesByCityRoute,
    salesBySceneRoute,
    goodsSellerRoute,
    orderListRoute,
    roleRoute,
    userRoute,
    platRoute,
    goodsRoute,
    displayRoute,
    manageUniformInterfaceAuthRoute,
];
