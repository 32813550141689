import {Route} from "@angular/router";

export const orderListRoute: Route = {
    path: "order-list",
    loadChildren: () => import('./order-list.module').then(m => m.OrderListModule),
    data: {
        menuParent: "order",
        menuLabel: "订单列表",
        requiredPrivilege: "order:orderlist:view"
    }
};
