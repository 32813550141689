import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import * as dayjs from "dayjs";
import { Injectable } from "@angular/core";

@Injectable()
export class DateSerializeInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.body) {
            this.convertDate(req.body);
        }
        return next.handle(req);
    }

    private convertDate(obj: Object) {
        for (const key of Object.keys(obj)) {
            if (obj[key] instanceof Date || dayjs.isDayjs(obj[key])) {
                obj[key] = obj[key].valueOf();
            } else if (obj[key] instanceof Object) {
                this.convertDate(obj[key]);
            }
        }
    }
}
