import {Route} from "@angular/router";

export const goodsSellerRoute: Route = {
    path: "goods-seller",
    loadChildren: () => import('./goods-seller.module').then(m => m.GoodsSellerModule),
    data: {
        menuParent: "statistics",
        menuLabel: "上机统计",
        requiredPrivilege: "statistics:goodsseller:view"
    }
};
