import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {IpsCollapseComponent} from "./ips-collapse.component";

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        IpsCollapseComponent
    ],
    declarations: [
        IpsCollapseComponent
    ]
})
export class IpsCollapseModule {
}
