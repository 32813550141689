import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {Router} from "@angular/router";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Observable, Subscriber} from "rxjs";
import {SERVER_API_PREFIX} from "../app.constants";
import {UserBaseInfo} from "./user-base-info";
import {LoadingMask} from "./loading-mask";

@Injectable({
    providedIn: "root"
})
export class UserInfoService {
    private static privilegeCodeList: Set<string> = new Set();
    private static lastUrl: string;
    private static _userBaseInfo: UserBaseInfo;
    private static _userInfoInitialized = false;

    constructor(private router: Router, private http: HttpClient) {
    }

    static get userBaseInfo(): UserBaseInfo {
        return JSON.parse(JSON.stringify(this._userBaseInfo || {})) || {};
    }

    static get userInfoInitialized(): boolean {
        return this._userInfoInitialized;
    }

    static hasPrivilege(privilegeCode: string) {
        if (!privilegeCode) {
            return true;
        } 
        else if (environment.production === false) {
            return true;
        }
        return UserInfoService.privilegeCodeList.has(privilegeCode);
    }

    private static setUserBaseInfo(userInfo: UserBaseInfo) {
        this._userBaseInfo = userInfo;
        this.privilegeCodeList.clear();
        if (userInfo.permissionList) {
            for (const code of [...userInfo.permissionList]) {
                this.privilegeCodeList.add(code);
            }
        }
        this._userInfoInitialized = true;
    }

    static clearUserBaseInfo() {
        this._userBaseInfo = undefined;
        this.privilegeCodeList.clear();
        this._userInfoInitialized = false;
    }

    static saveLastUrl(url: string) {
        UserInfoService.lastUrl = url;
    }

    initUserBaseInfo(): Observable<boolean> {
        return new Observable((subscriber: Subscriber<boolean>) => {
            this.http
                .skipErrorHandler()
                .get(`${SERVER_API_PREFIX}/common/auth/userInfo`)
                .subscribe((res: UserBaseInfo) => {
                    UserInfoService.setUserBaseInfo(res);
                    if (environment.production === false) {
                        console.log(`user base info已获取并设置：${JSON.stringify(res)}`);
                    }
                    LoadingMask.remove();
                    subscriber.next(true);
                }, err => {
                    if (err instanceof HttpErrorResponse) {
                        console.log("初始化失败");
                    }
                    if (environment.production === false) {
                        console.warn("初始化用户信息失败，开发模式下允许跳转至主页");
                        UserInfoService.setUserBaseInfo({userId: 1, username: "admin", site: "mg"});
                        LoadingMask.remove();
                        subscriber.next(true);
                    } else {
                        this.router.navigate(["/login"]).then(() => {
                        });
                        subscriber.next(false);
                    }
                });
        });
    }

    getMinimumPasswordScore(): Observable<any> {
        return this.http.get(`${SERVER_API_PREFIX}/user-info/minimumPasswordScore`);
    }

    getPasswordScore(params): Observable<any> {
        return this.http.patch(`${SERVER_API_PREFIX}/user-info/passwordScore`, params);
    }

    modifyPassword(params): Observable<any> {
        return this.http.post(`${SERVER_API_PREFIX}/user-info/update/password`, params);
    }

    logout(): Observable<any> {
        return this.http.delete(`${SERVER_API_PREFIX}/common/auth/logout`);
    }

    jumpToLastUrl() {
        if (!UserInfoService.lastUrl || UserInfoService.lastUrl === "/login") {
            this.router.navigate(["/"]);
        } else {
            this.router.navigate([UserInfoService.lastUrl]);
        }
    }
}
