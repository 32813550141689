import {RouterModule, Routes} from "@angular/router";
import {LoginComponent} from "./global/login/login.component";
import {NgModule} from "@angular/core";
import {environment} from "../environments/environment";
import {MainComponent} from "./pages/public/main/main.component";
import {pagesRoute} from "./pages/pages.route";
import {PrivilegeGuard} from "./global/route-guard/privilege-guard";
import {InitUserGuard} from "./global/route-guard/init-user-guard";

const routes: Routes = [
    {
        path: "login",
        component: LoginComponent,
        canActivate: [InitUserGuard]
    },
    {
        path: "",
        component: MainComponent,
        children: pagesRoute,
        canActivate: [InitUserGuard],
        canActivateChild: [PrivilegeGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        useHash: true,
        enableTracing: !environment.production
    })],
    exports: [RouterModule]
})

export class AppRoutingModule {
}
