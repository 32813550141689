import {Route} from "@angular/router";

export const goodsRoute: Route = {
    path: "goods-manage",
    loadChildren: () => import('./goods.module').then(m => m.GoodsModule),
    data: {
        menuParent: "system",
        menuLabel: "商品管理",
        requiredPrivilege: "system:goods:view"
    }
};
