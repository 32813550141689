import {Component, ElementRef, HostBinding, Input, OnInit} from "@angular/core";
import {toBoolean} from "ng-zorro-antd";
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
    selector: "ips-collapse",
    templateUrl: "./ips-collapse.component.html",
    styleUrls: ["./ips-collapse.component.less"],
    animations: [
        trigger("collapseState", [
            state("active", style({
                opacity: "0",
                height: 0
            })),
            state("inactive", style({
                opacity: "1",
                height: "*"
            })),
            transition("inactive => active", animate("150ms ease-in")),
            transition("active => inactive", animate("150ms ease-out"))
        ])
    ],
    styles: [
            `
            :host {
                display: block
            }
            :first-child {
                overflow: hidden;
            }`
    ]
})
export class IpsCollapseComponent implements OnInit {
    private _collapsed = false;
    private el: ElementRef;

    @Input()
    @HostBinding("class.collapsed")
    set isCollapsed(value: boolean) {
        this._collapsed = toBoolean(value);
    }

    get isCollapsed() {
        return this._collapsed;
    }

    constructor(elementRef: ElementRef) {
        this.el = elementRef.nativeElement;
    }

    ngOnInit() {
    }

}
