import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule } from "@angular/forms";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import {
    NgZorroAntdModule,
    NZ_CONFIG,
    NZ_I18N,
    NzConfig,
    NzModalService,
    zh_CN,
} from "ng-zorro-antd";
import { registerLocaleData } from "@angular/common";
import zh from "@angular/common/locales/zh";
import { AppRoutingModule } from "./app-routing.module";
import { LoginModule } from "./global/login/login.module";
import { PagesModule } from "./pages/pages.module";
import { HttpService } from "./global/request-utils/http.service";
import { HandleErrorInterceptor } from "./global/request-utils/handle-error-interceptor";
import { ResponseSimplifyInterceptor } from "./global/request-utils/response-simplify-interceptor";
import { DateSerializeInterceptor } from "./global/request-utils/date-serialize-interceptor";
import { StandardModalService } from "./global/standard-modal.service";

registerLocaleData(zh);

const ngZorroConfig: NzConfig = {
    message: {
        nzDuration: 5000,
    },
    table: {
        nzBordered: true,
    },
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        NgZorroAntdModule,
        AppRoutingModule,
        LoginModule,
        PagesModule,
    ],
    providers: [
        HandleErrorInterceptor,
        ResponseSimplifyInterceptor,
        DateSerializeInterceptor,
        { provide: NZ_I18N, useValue: zh_CN },
        { provide: NZ_CONFIG, useValue: ngZorroConfig },
        { provide: NzModalService, useClass: StandardModalService },
        { provide: HttpClient, useClass: HttpService },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
