import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {SERVER_API_PREFIX} from "../../app.constants";
import {LoginReq} from "./login-req";

@Injectable(
    {providedIn: "root"}
)
export class LoginService {

    constructor(private http: HttpClient) {
    }

    login(params: LoginReq): Observable<any> {
        return this.http
            .skipResponseSimplify()
            .skipErrorHandler()
            .post(`${SERVER_API_PREFIX}/common/auth/login`, params);
    }
}
