import {Route} from "@angular/router";

export const displayRoute: Route = {
    path: "display-manage",
    loadChildren: () => import('./display.module').then(m => m.DisplayModule),
    data: {
        menuParent: "system",
        menuLabel: "陈列设置"
        // requiredPrivilege: "system:display:view"
    }
};
