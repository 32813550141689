import {Route} from "@angular/router";
// ./pages/statistics/sales-by-goods/sales-by-goods.module

export const salesByGoodsRoute: Route = {
    path: "sales-by-goods",
    loadChildren: () => import('./sales-by-goods.module').then(m => m.SalesByGoodsModule),
    data: {
        menuParent: "statistics",
        menuLabel: "商品销售",
        requiredPrivilege: "statistics:goodssale:view"
    }
};
