// import {Inject, Injectable, InjectionToken, Injector, Optional} from "@angular/core";
import {Observable} from "rxjs";
import {HandleErrorInterceptor} from "./handle-error-interceptor";
import {ResponseSimplifyInterceptor} from "./response-simplify-interceptor";
import {DateSerializeInterceptor} from "./date-serialize-interceptor";
import {HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Inject, Injectable, InjectionToken, Injector, Optional} from "@angular/core";

declare module "@angular/common/http/" {
    export interface HttpClient {

        /**
         * 跳过全局错误处理，需自行处理并提示错误情况
         */
        skipErrorHandler(): HttpClient;

        /**
         * 跳过服务端返回简化，result === 0的情况也将进入成功回调，需自行判断
         */
        skipResponseSimplify(): HttpClient;

        /**
         * 跳过日期序列化处理
         */
        skipDateSerialize(): HttpClient;

    }
}

class HttpInterceptorHandler implements HttpHandler {

    constructor(private next: HttpHandler, private interceptor: HttpInterceptor) {
    }

    handle(request: HttpRequest<any>): Observable<HttpEvent<any>> {
        return this.interceptor.intercept(request, this.next);
    }

}

export const HTTP_DYNAMIC_INTERCEPTORS = new InjectionToken<HttpInterceptor>("HTTP_DYNAMIC_INTERCEPTORS");

@Injectable()
export class HttpService extends HttpClient {

    constructor(private httpHandler: HttpHandler,
                private injector: Injector,
                @Optional() @Inject(HTTP_DYNAMIC_INTERCEPTORS) private readonly interceptors: HttpInterceptor[] = []) {
        super(httpHandler);

        if (!this.interceptors) {
            this.interceptors = [
                this.injector.get(HandleErrorInterceptor),
                this.injector.get(ResponseSimplifyInterceptor),
                this.injector.get(DateSerializeInterceptor)
            ];
        }
    }

    /**
     * 跳过全局错误处理
     */
    skipErrorHandler(): HttpClient {
        return this.removeInterceptor(HandleErrorInterceptor);
    }

    /**
     * 跳过返回值简化
     */
    skipResponseSimplify(): HttpClient {
        return this.removeInterceptor(ResponseSimplifyInterceptor);
    }

    /**
     * 跳过日期自动处理（日期、dayjs对象转毫秒数），仅限requestBody
     */
    skipDateSerialize(): HttpClient {
        return this.removeInterceptor(DateSerializeInterceptor);
    }

    request(method?: any, url?: any, options?: any): any {
        const handler = this.interceptors.reduceRight(
            (next, interceptor) => new HttpInterceptorHandler(next, interceptor),
            this.httpHandler
        );
        return new HttpClient(handler).request(method, url, options);
    }

    private removeInterceptor(interceptorType: Function): HttpService {
        return new HttpService(
            this.httpHandler,
            this.injector,
            this.interceptors.filter(i => !(i instanceof interceptorType))
        );
    }
}
