export const SERVER_API_PREFIX = "/api";
export const PICTURE_PATH = "http://";
export const MACHINE_DEVICE_PICTURE_SIZE = "?imageView2/5/h/200/w/200";
export const PICTURE_TAILORING_PATH_LAST = "?imageView2/5/h/100/w/100";
export const HORIZONTAL_PICTURE_TAILORING_PATH_LAST = "?imageView2/5/h/150/w/200";
export const ORGANIZATION_PICTURE_TAILORING_PATH_LAST = "?imageView2/5/h/150/w/200";
export const VERTICAL_PICTURE_TAILORING_PATH_LAST = "?imageView2/5/h/150/w/100";
export const PICTURE_LIST_TAILORING_PATH_LAST = "?imageView2/5/h/60/w/60";
export const PAY_PLATFORM_MAP = {
    "0": "会员卡",
    "1": "支付宝",
    "2": "微信支付",
    "3": "现金支付",
    "4": "银联支付",
    "5": "快付",
    "6": "京东支付",
    "7": "HIK",
    "8": "银联云闪付",
    "9": "银商聚合",
    "10": "IPS聚合支付",
    "11": "天猫云码",
    "12": "工行支付",
    "13": "工行数字钱包",
    "14": "苏州中行数字货币"
};
export const PAY_PLATFORM_LIST = Object.keys(PAY_PLATFORM_MAP).map(key => ({name: PAY_PLATFORM_MAP[key], value: key}));
export const PAY_PRODUCT_MAP = {
    "bar_code": "条码支付",
    "wave_code": "声波支付",
    "security_code": "扫脸支付",
    "": "扫码支付",
    "micro_pay": "条码支付",
    "face_pay": "扫脸支付",
    "union_face": "银联扫脸"
};
export const TRADE_STATUS_MAP = {
    "0": "创建订单",
    "1": "生成二维码",
    "2": "等待扫码",
    "3": "已支付",
    "4": "申请退款",
    "5": "已退款",
    "6": "订单关闭中",
    "7": "订单已关闭"
};
export const GEO_COORD_MAP_OPTIONS = {
    "江苏": [118.8062, 31.9208],
    "黑龙江": [127.9688, 45.368],
    "内蒙古": [110.3467, 41.4899],
    "吉林": [125.8154, 44.2584],
    "北京": [116.4551, 40.2539],
    "辽宁": [123.1238, 42.1216],
    "河北": [114.4995, 38.1006],
    "天津": [117.4219, 39.4189],
    "山西": [112.3352, 37.9413],
    "陕西": [109.1162, 34.2004],
    "甘肃": [103.5901, 36.3043],
    "宁夏": [106.3586, 38.1775],
    "青海": [101.4038, 36.8207],
    "新疆": [87.9236, 43.5883],
    "四川": [103.9526, 30.7617],
    "重庆": [108.384366, 30.439702],
    "山东": [117.1582, 36.8701],
    "河南": [113.4668, 34.6234],
    "安徽": [117.29, 32.0581],
    "湖北": [114.3896, 30.6628],
    "浙江": [119.5313, 29.8773],
    "福建": [119.4543, 25.9222],
    "江西": [116.0046, 28.6633],
    "湖南": [113.0823, 28.2568],
    "贵州": [106.6992, 26.7682],
    "云南": [102.9199, 25.4663],
    "广东": [113.12244, 23.009505],
    "广西": [108.479, 23.1152],
    "海南": [110.3893, 19.8516],
    "上海": [121.4648, 31.2891],
};
