import {Route} from "@angular/router";

export const roleRoute: Route = {
    path: "role-manage",
    loadChildren: () => import('./role.module').then(m => m.RoleModule),
    data: {
        menuParent: "system",
        menuLabel: "角色管理",
        requiredPrivilege: "system:role:view"
    }
};
