import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpUserEvent} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable, throwError} from "rxjs";
import {map} from "rxjs/operators";

@Injectable()
export class ResponseSimplifyInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req)
            .pipe(map(event => {
                if (event instanceof HttpResponse) {
                    if (event.body && event.body.result === 1) {
                        return event.clone({ body: event.body.success });
                    } else if(req.body instanceof FormData){
                        return event;
                    } else {
                        throw event;
                    }
                }
                return event;
            }));
    }
}
