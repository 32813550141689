export const menuCategory = {
    statistics: {
        label: "统计",
        icon: "stock",
        index: 1,
        children: [],
    },
    order: {
        label: "订单",
        icon: "shopping",
        index: 2,
        children: [],
    },
    user: {
        label: "用户",
        icon: "user",
        index: 3,
        children: [],
    },
    system: {
        label: "系统",
        icon: "tool",
        index: 4,
        children: [],
    },
    intellect: {
        label: "智能分析",
        icon: "eye",
        index: 5,
        children: [],
    },
    interface: {
        label: "接口管理",
        icon: "sliders",
        index: 6,
        children: [],
    },
    other: {
        label: "其他",
        index: 1000,
        children: [],
    },
};
