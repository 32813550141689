import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {LoginComponent} from "./login.component";
import {ReactiveFormsModule} from "@angular/forms";
import {NgZorroAntdModule} from "ng-zorro-antd";
import {IpsCollapseModule} from "../../components/ips-collapse/ips-collapse.module";

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NgZorroAntdModule,
        IpsCollapseModule
    ],
    declarations: [LoginComponent]
})
export class LoginModule {
}
