import { Route } from "@angular/router";
import { PopularizeModule } from "./popularize.module";

export const popularizeRoute: Route = {
    path: "popularize",
    loadChildren:
        () => import('./popularize.module').then(m => m.PopularizeModule),
    data: {
        menuParent: "intellect",
        menuLabel: "新品推广",
        requiredPrivilege: "intellect:popularize:view",
    },
};
