import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgZorroAntdModule, NzModalService } from "ng-zorro-antd";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MainComponent } from "./public/main/main.component";
import { AppRoutingModule } from "../app-routing.module";
import { DashboardComponent } from "./public/dashboard/dashboard.component";
import { StandardModalService } from "../global/standard-modal.service";
import { ComponentsModule } from "../components/components.module";
import { PasswordModifyComponent } from "./public/main/password-modify/password-modify.component";
import { NgxEchartsModule } from "ngx-echarts";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgZorroAntdModule,
        NgxEchartsModule,
        AppRoutingModule,
        ComponentsModule,
    ],
    entryComponents: [PasswordModifyComponent],
    providers: [{ provide: NzModalService, useClass: StandardModalService }],
    declarations: [MainComponent, DashboardComponent, PasswordModifyComponent],
})
export class PagesModule {}
