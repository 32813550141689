import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LoginService } from "./login.service";
import { UserInfoService } from "../user-info.service";
import { NzMessageService } from "ng-zorro-antd/message";

@Component({
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.less"],
})
export class LoginComponent implements OnInit {
    validateForm: FormGroup;
    loading: false;
    errMessage = "";

    constructor(
        private fb: FormBuilder,
        private loginService: LoginService,
        private message: NzMessageService,
        private privilegeService: UserInfoService
    ) {}

    formChanged() {
        this.errMessage = "";
    }
    forgotPwd() {
        this.message.info("请联系管理员");
    }

    submitForm(): void {
        for (const i of Object.keys(this.validateForm.controls)) {
            this.validateForm.controls[i].markAsDirty();
            this.validateForm.controls[i].updateValueAndValidity();
        }
        if (this.validateForm.valid) {
            this.loginService.login(this.validateForm.getRawValue()).subscribe(
                (response) => {
                    if (response.result === 1) {
                        if (this.validateForm.get("remember").value) {
                            localStorage.setItem(
                                "saved-login-name",
                                this.validateForm.get("username").value
                            );
                        } else {
                            localStorage.removeItem("saved-login-name");
                        }
                        this.privilegeService.jumpToLastUrl();
                    } else {
                        this.errMessage = response.fault.message;
                    }
                },
                (err) => {
                    this.errMessage = err;
                }
            );
        }
    }

    ngOnInit(): void {
        this.validateForm = this.fb.group({
            username: [
                localStorage.getItem("saved-login-name"),
                [Validators.required],
            ],
            password: [null, [Validators.required]],
            remember: [true],
        });
    }
}
