import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {StandardTitleBarComponent} from "./standard-title-bar.component";

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        StandardTitleBarComponent
    ],
    declarations: [
        StandardTitleBarComponent
    ]
})
export class StandardTitleBarModule {
}
