import {Host, Injectable, Injector} from "@angular/core";
import {ModalOptions, NzModalRef, NzModalService} from "ng-zorro-antd/modal";
import { NzSafeAny } from "ng-zorro-antd/core/types";
import {Overlay} from "@angular/cdk/overlay";
import { NzConfigService } from "ng-zorro-antd";

declare module "ng-zorro-antd/modal/modal.service" {
    export interface NzModalService {
        createNoFooter<T>(options: ModalOptions<T>): NzModalRef<T>;
    }
}

@Injectable()
export class StandardModalService extends NzModalService {
    private defaultModalOptions: ModalOptions = {
        nzMaskClosable: false,
        nzWidth: 620
    };
    create<T, R = NzSafeAny>(config: ModalOptions<T, R>): NzModalRef<T, R> {
        return super.create({
            ...this.defaultModalOptions,
            ...config,
        });
    }
    createNoFooter<T>(config: ModalOptions<T>): NzModalRef<T> {
        return super.create({
            ...this.defaultModalOptions,
            nzFooter: null,
            nzBodyStyle: { padding: "0" },
            ...config,
        });
    }

    confirm<T>(options?: ModalOptions<T>): NzModalRef<T> {
        return super.confirm({
            ...this.defaultModalOptions,
            ...options
        });
    }

    info<T>(options?: ModalOptions<T>): NzModalRef<T> {
        return super.info({
            ...this.defaultModalOptions,
            ...options
        });
    }

    success<T>(options?: ModalOptions<T>): NzModalRef<T> {
        return super.success({
            ...this.defaultModalOptions,
            ...options
        });
    }

    error<T>(options?: ModalOptions<T>): NzModalRef<T> {
        return super.error({
            ...this.defaultModalOptions,
            ...options
        });
    }

    warning<T>(options?: ModalOptions<T>): NzModalRef<T> {
        return super.warning({
            ...this.defaultModalOptions,
            ...options
        });
    }
}
