import {Component, Input, OnInit} from "@angular/core";
import {pagesRoute} from "../../pages.route";
import {UserInfoService} from "../../../global/user-info.service";
import {menuCategory} from "./menu-category";
import {UserBaseInfo} from "../../../global/user-base-info";
import {NzModalService} from "ng-zorro-antd";
import {PasswordModifyComponent} from "./password-modify/password-modify.component";

@Component({
    selector: "app-main",
    templateUrl: "./main.component.html",
    styleUrls: ["./main.component.less"]
})
export class MainComponent implements OnInit {
    private _collapsed: boolean;
    menu = [];
    userInfo: UserBaseInfo;

    @Input()
    get isCollapsed(): boolean {
        return this._collapsed;
    }

    set isCollapsed(val: boolean) {
        this._collapsed = val;
        localStorage.setItem("main-menu-collapsed", String(val));
    }

    constructor(private modalService: NzModalService, private userInfoService: UserInfoService) {
        setTimeout(() => {
            this.isCollapsed = localStorage.getItem("main-menu-collapsed") === "true";
        });
    }

    modifyPassword() {
        this.modalService
            .create({
                nzTitle: "修改密码",
                nzContent: PasswordModifyComponent
            });
    }

    logout() {
        this.modalService.confirm({
            nzTitle: "退出登录？",
            nzContent: "确认要退出当前登录状态？",
            nzOnOk: () => {
                this.userInfoService
                    .logout()
                    .subscribe(() => {
                        window.location.reload();
                    });
            }
        });
    }

    ngOnInit() {
        this.userInfo = UserInfoService.userBaseInfo;
        const menu = JSON.parse(JSON.stringify(menuCategory));
        for (const page of pagesRoute) {
            if (page.data && page.data.menuLabel) {

                if(page.data.menuLabel === "陈列设置"){
                    if(this.userInfo.username === "admin"){
                        menu[page.data.menuParent].children.push({
                            label: page.data.menuLabel,
                            path: `/${page.path}`
                        });
                    }
                }else if(!page.data.requiredPrivilege || UserInfoService.hasPrivilege(page.data.requiredPrivilege)) {
                    if (!menu[page.data.menuParent]) {
                        page.data.menuParent = "other";
                    }
                    menu[page.data.menuParent].children.push({
                        label: page.data.menuLabel,
                        path: `/${page.path}`
                    });
                }
            }
        }
        const menuArray = [];
        for (const key of Object.keys(menu)) {
            if (menu[key]) {
                menuArray.push(menu[key]);
            }
        }
        menuArray.sort((a, b) => {
            if (a.index && b.index) {
                return a.index - b.index;
            }
            return 0;
        });
        this.menu = menuArray;
    }

}
