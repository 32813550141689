import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {IpsCollapseModule} from "./ips-collapse/ips-collapse.module";
import {StandardTitleBarModule} from "./standard-title-bar/standard-title-bar.module";
import {RequiresPermissionsDirective} from "./requires-permissions/requires-permissions.directive";
import { NzUploadModule } from "ng-zorro-antd/upload";
import {SimplePercentTrendComponent} from "./simple-percent-trend/simple-percent-trend.component";
import {NzIconModule} from "ng-zorro-antd";

@NgModule({
    imports: [
        CommonModule,
        NzIconModule,
    ],
    exports: [
        IpsCollapseModule,
        StandardTitleBarModule,
        RequiresPermissionsDirective,
        NzUploadModule,
        SimplePercentTrendComponent,
    ],
    declarations: [RequiresPermissionsDirective, SimplePercentTrendComponent]
})
export class ComponentsModule {
}
