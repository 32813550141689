import {Route} from "@angular/router";

export const platRoute: Route = {
    path: "plat-manage",
    loadChildren: () => import('./plat.module').then(m => m.PlatModule),
    data: {
        menuParent: "system",
        menuLabel: "平台管理",
        requiredPrivilege: "system:plat:view"
    }
};
