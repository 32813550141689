import {Route} from "@angular/router";

export const totalStatisticsRoute: Route = {
    path: "total-statistics",
    loadChildren: () => import('./total-statistics.module').then(m => m.TotalStatisticsModule),
    data: {
        menuParent: "statistics",
        menuLabel: "数据视图",
        requiredPrivilege: "statistics:dataview:view"
    }
};
