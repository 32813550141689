<nz-layout class="full-screen">
    <nz-header>
        <a class="logo-link" routerLink="/">
            <img src="../../../../assets/images/yunhao.png" style="width: 130px;height: 40px;margin-left: 20px;"/>
        </a>
        <div class="info-area">
            <span *ngIf="userInfo?.merchantName" nz-tooltip [nzTitle]="userInfo?.merchantId" nzTooltipPlacement="left"
                [nzMouseEnterDelay]="1">
                <i nz-icon nzType="shop" nzTheme="fill"></i>&nbsp;{{userInfo?.merchantName}}
                <nz-divider nzType="vertical"></nz-divider>
            </span>
            <a nz-dropdown [nzDropdownMenu]="userInfoMenu" class="user-name" nzTrigger="click">
                <i nz-icon nzType="user"></i>
                &nbsp;{{userInfo?.username}}&nbsp;
                <i nz-icon nzType="down"></i>
            </a>
            <nz-dropdown-menu #userInfoMenu="nzDropdownMenu">
                <ul nz-menu>
                    <li nz-menu-item nzDisabled>账号信息</li>
                    <li nz-menu-item (click)="modifyPassword()">修改密码</li>
                    <li nz-menu-item (click)="logout()">退出登录</li>
                </ul>
            </nz-dropdown-menu>
        </div>
    </nz-header>
    <nz-layout>
        <nz-sider nzCollapsible [(nzCollapsed)]="isCollapsed" [nzWidth]="200" [nzCollapsedWidth]="70">
            <ul nz-menu style="height: 100%" [nzMode]="'inline'" [nzInlineCollapsed]="isCollapsed">
                <ng-container *ngFor="let cat of menu">
                    <li nz-submenu *ngIf="cat.children.length > 0">
                        <span title>
                            <i nz-icon [nzType]="cat.icon || 'ellipsis'"></i>
                            <span class="nav-text">{{cat.label}}</span>
                        </span>
                        <ul>
                            <li *ngFor="let item of cat['children']" nz-menu-item>
                                <a [routerLink]="item['path']">{{item['label']}}</a>
                            </li>
                        </ul>
                    </li>
                </ng-container>
            </ul>
        </nz-sider>
        <nz-content id="main-content">
            <div id="page-container">
                <router-outlet></router-outlet>
            </div>
        </nz-content>
    </nz-layout>
</nz-layout>