<nz-spin [nzSpinning]="spinning">
    <form nz-form [formGroup]="validateForm" (submit)="submit()">
        <div class="ant-modal-body">
            <nz-form-item>
                <nz-form-label [nzSm]="4" [nzXs]="24" nzFor="currentPassword" nzRequired> 当前密码 </nz-form-label>
                <nz-form-control [nzSm]="20" [nzXs]="24">
                    <input nz-input type="password" formControlName="currentPassword" id="currentPassword" />
                    <nz-form-explain *ngIf="validateForm.get('currentPassword').dirty && validateForm.get('currentPassword').errors"> 请输入当前密码 </nz-form-explain>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSm]="4" [nzXs]="24" nzFor="newPassword" nzRequired> 新密码 </nz-form-label>
                <nz-form-control [nzSm]="20" [nzXs]="24">
                    <input nz-input type="password" formControlName="newPassword" id="newPassword" (ngModelChange)="newPassChange($event)" />
                    <nz-form-explain *ngIf="validateForm.get('newPassword').dirty && validateForm.get('newPassword').errors">
                        <ng-container *ngIf="validateForm.get('newPassword').hasError('required')"> 请输入新密码 </ng-container>
                        <ng-container *ngIf="validateForm.get('newPassword').hasError('tooWeak')"> 密码强度太弱 </ng-container>
                    </nz-form-explain>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSm]="4" [nzXs]="24"> 密码强度 </nz-form-label>
                <nz-form-control [nzSm]="20" [nzXs]="24">
                    <nz-progress [nzPercent]="score * 25" [nzStatus]="progressStatus[score]" [nzShowInfo]="false"></nz-progress>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSm]="4" [nzXs]="24" nzFor="confirmPassword" nzRequired> 确认密码 </nz-form-label>
                <nz-form-control [nzSm]="20" [nzXs]="24">
                    <input nz-input type="password" formControlName="confirmPassword" id="confirmPassword" />
                    <nz-form-explain *ngIf="validateForm.get('confirmPassword').dirty && validateForm.get('confirmPassword').errors">
                        <ng-container *ngIf="validateForm.get('confirmPassword').hasError('required')"> 请输入新密码 </ng-container>
                        <ng-container *ngIf="validateForm.get('confirmPassword').hasError('notEquals')"> 必需与新密码相同 </ng-container>
                    </nz-form-explain>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item style="margin-left: 25px">
                <nz-form-label> 密码要求 </nz-form-label>
                <span style="margin-top: 5px">大写字母,小写字母,数字,特殊字符任意两种及以上的组合,至少8位</span>
            </nz-form-item>
        </div>
        <div class="ant-modal-footer">
            <button nz-button type="button" (click)="close()">取消</button>
            <button nz-button type="submit" nzType="primary" [disabled]="!validateForm.valid">确定</button>
        </div>
    </form>
</nz-spin>
