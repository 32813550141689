import {Component, Input, OnInit} from "@angular/core";

@Component({
    selector: "simple-percent-trend",
    templateUrl: "./simple-percent-trend.component.html",
    styleUrls: ["./simple-percent-trend.component.less"]
})
export class SimplePercentTrendComponent implements OnInit {
    @Input()
    value: number;

    get absValue() {
        return Math.abs(this.value);
    }

    get textColor() {
        return this.value === 0 || this.value === null
            ? null
            : this.value > 0
                ? "red"
                : "green";
    }

    get iconType() {
        return this.value === 0 || this.value === null
            ? "ellipsis"
            : this.value > 0
                ? "arrow-up"
                : "arrow-down";
    }

    constructor() {
    }

    ngOnInit() {
    }

}
