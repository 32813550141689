<div id="login-page">
    <!-- 登陆页面最底层大背景图片 -->
    <img src="../../../assets/images/login-background.png" class="img-bg" />
    <!-- 登录页面第二层半透明背景图片 -->
    <div class="img-bg-translucent">
        <div class="title-box">
            <div class="left-box">
                <img
                    src="../../../assets/images/yh-default-icon.png"
                    class="icon"
                />
                <div class="welcome">Welcome</div>
                <div class="title">大象智贩数据中岛平台</div>
            </div>
            <div class="company">上海云灏智能数字科技有限公司</div>
        </div>
    </div>
    <!-- <div class="login-topbar">
        <img [src]="customizeInfo.logoUrl" *ngIf="customizeInfo.logoUrl" />
        <h1>{{ customizeInfo.title }}</h1>
    </div> -->
    <div class="login-panel">
        <!-- <h1>{{ customizeInfo.loginPanelTitle }}</h1> -->
        <h1>登录</h1>
        <!--    <nz-tabset [nzType]="'line'" [nzSelectedIndex]="tabIndex">-->
        <!--      <nz-tab *ngFor="let tab of tabs" [nzTitle]="tab">-->
        <form
            nz-form
            [formGroup]="validateForm"
            class="login-form"
            (ngSubmit)="submitForm()"
            (change)="formChanged()"
        >
            <nz-form-item>
                <nz-form-control nzErrorTip="请输入用户名或邮箱">
                    <nz-input-group>
                        <input
                            type="text"
                            nz-input
                            formControlName="username"
                            placeholder="请输入用户名/邮箱"
                            (ngModelChange)="formChanged()"
                        />
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control nzErrorTip="请输入密码">
                    <nz-input-group>
                        <input
                            type="password"
                            nz-input
                            formControlName="password"
                            placeholder="请输入密码"
                            (ngModelChange)="formChanged()"
                        />
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control>
                    <div class="login-from-user-button">
                        <div>
                            <!-- <label nz-checkbox formControlName="remember">
                                <span class="user-size">记住用户名</span>
                            </label> -->
                            <a class="login-form-forgot" (click)="forgotPwd()"
                                >忘记密码?</a
                            >
                        </div>
                        <button
                            nz-button
                            [nzLoading]="loading"
                            class="login-form-button"
                        >
                            登录
                        </button>
                    </div>
                </nz-form-control>
            </nz-form-item>
        </form>
        <!--      </nz-tab>-->
        <!--    </nz-tabset>-->
        <ips-collapse [isCollapsed]="errMessage === ''">
            <div class="err-panel">{{ errMessage }}</div>
        </ips-collapse>
    </div>
</div>
