import { Route } from "@angular/router";

export const manageUniformInterfaceAuthRoute: Route = {
    path: "manage-uniform-interface-auth",
    loadChildren: () =>
        import("./manage-uniform-interface-auth.module").then(
            (m) => m.ManageUniformInterfaceAuthModule
        ),
    data: {
        menuParent: "interface",
        menuLabel: "对外商户列表",
    },
};
