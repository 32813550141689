import {Route} from "@angular/router";

export const salesByCityRoute: Route = {
    path: "sales-by-city",
    loadChildren: () => import('./sales-by-city.module').then(m => m.SalesByCityModule),
    data: {
        menuParent: "statistics",
        menuLabel: "城市销售",
        requiredPrivilege: "statistics:citysale:view"
    }
};
